import { defineMessages } from 'react-intl';

export const sharedText = defineMessages({
	siteTitle: {
		id: 'Shared_SiteName',
		defaultMessage: 'Online Chain of Custody System'
	},
	login: {
		id: 'Login_LoginBtn',
		defaultMessage: 'Log In'
	},
	confirmDelete: {
		id: 'CollectionDashboard_RemoveQuestion',
		defaultMessage: 'Are you sure you want to remove this collection?'
	},
	cannotBeUndone: {
		id: 'CcfPwa_ThisActionCannotBeUndone',
		defaultMessage: 'This action cannot be undone.'
	},
	yesDelete: {
		id: 'CcfPwa_YesDelete',
		defaultMessage: 'Yes, delete'
	},
	noCancel: {
		id: 'CcfPwa_NoReturnToCollection',
		defaultMessage: 'No, return to collection'
	},
	syncing: {
		id: 'CcfPwa_Syncing',
		defaultMessage: 'Syncing...'
	},
	lastUpdated: {
		id: 'CcfPwa_LastUpdated',
		defaultMessage: 'Last updated:'
	},
	confirmExit: {
		id: 'CcfPwa_AreYouSureYouWantToSaveAndExit',
		defaultMessage: 'Are you sure you want to save and exit?'
	},
	haventFinishedCollection: {
		id: 'CcfPwa_NotYetFinishedCollection',
		defaultMessage: 'You have not yet finished this collection. Are you sure you want to save and exit?'
	},
	yesSaveAndExit: {
		id: 'CcfPwa_YesSaveAndExit',
		defaultMessage: 'Yes, save and exit'
	},
	required: {
		id: 'Shared_Required',
		defaultMessage: 'Required'
	}
});
