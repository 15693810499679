import type { CollectionExport } from '@/types/global';

type Translations = {
	key: string;
	value: string;
};

export const translations: CollectionExport<Translations> = {
	schema: {
		title: 'translations',
		version: 1,
		primaryKey: 'key',
		type: 'object',
		properties: {
			key: {
				type: 'string',
				maxLength: 5
			},
			value: {
				type: 'string'
			}
		}
	}
};
