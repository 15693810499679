import toast from 'react-hot-toast';

import { getSavingParams } from '@/constants/routes';
import { saveErrors } from '@/constants/saveErrors';
import { getCurrentTimestamp, getDateTimestamp } from '@/services/date';
import { getCollection } from '@/services/webservices';
import { initialize as initializeCollections } from '@/stores/db/initializeCollections';
import { CURRENT_COLLECTION_SITE_KEY, getStorageItem } from '@/stores/localstorage';

import { syncStatusService } from '@/stores/xstate/sync/syncStatusMachine';

type SyncResult = {
	collectionId: string;
	isError: boolean;
	error?: string;
};

async function getSyncableRecords( collection: any ) {
	const currentCollectionSite = getStorageItem( CURRENT_COLLECTION_SITE_KEY );

	// get records that belong to the collection site, aren't synced, and that don't have errors
	const syncableRecords = await collection.find({
		selector: {
			collectionSiteId: { $eq: currentCollectionSite },
			$and: [
				{ $or: [ { hasError: { $eq: false } }, { hasError: { $exists: false } } ] },
				{ $or: [ { isSynced: { $eq: false } }, { isSynced: { $exists: false } } ] }
			]
		}
	}).exec();

	if ( syncableRecords ) {
		return syncableRecords.map( ( doc: any ) => {
			return doc.toJSON();
		});
	}

	return [];
}

async function syncSingleRecord( doc: any ): Promise<SyncResult> {
	try {
		const updateObj = {
			...doc.collectionObject,
			id: doc.collectionId
		};

		let updateParams = getSavingParams( updateObj.meta.lastStepCompleted );

		if ( updateParams.finishCollection ) {
			updateParams = {
				...updateParams,
				userPassword: updateObj.meta.collectorPwd
			};
		}

		if ( updateObj.isDonorWithdrawal ) {
			updateParams = {
				...updateParams,
				isDonorWithdrawClicked: true
			};
		}

		// delete properties that are internal state specific
		delete updateObj.meta;

		let updateData = {
			collection: updateObj,
			...updateParams
		};

		// save the collection itself
		const saveRequest = await fetch( '/api/saveCollection', {
			method: 'POST',
			body: JSON.stringify( updateData ),
			headers: {
				'Content-Type': 'application/json'
			}
		});

		const data = await saveRequest.json();

		// If the sync wasn't successful, log an error
		if ( !saveRequest.ok ) {
			const error = ( data && data.message ) || saveRequest.status;

			return Promise.resolve({
				collectionId: doc.collectionId,
				isError: true,
				error
			});
		} else {
			// If the request was successful but returned an error:
			if ( !data.successful ) {
				const errorMessage = saveErrors[ data.saveResult ]?.message || 'There was an error syncing this record. Please try again.';

				return Promise.resolve({
					collectionId: doc.collectionId,
					error: errorMessage,
					isError: true
				});
			}
		}

		// If the collection has a consent object, save the consent object:
		if ( updateData.collection.collectionConsent ) {
			const saveConsentRequest = await fetch( '/api/saveCollectionConsent', {
				method: 'POST',
				body: JSON.stringify({
					...updateData,
					collectionConsent: updateData.collection.collectionConsent
				}),
				headers: {
					'Content-Type': 'application/json'
				}
			});

			const consentData = await saveConsentRequest.json();

			if ( !saveConsentRequest.ok ) {
				return Promise.resolve({
					collectionId: doc.collectionId,
					isError: true,
					error: ( consentData && consentData.message ) || saveConsentRequest.status
				});
			} else {
				if ( !consentData.successful ) {
					const consentError = saveErrors[ consentData.result ]?.message || 'There was an unknown error saving the collection consent.';
					
					return Promise.resolve({
						collectionId: doc.collectionId,
						isError: true,
						error: `Syncing collection ${ doc.collectionId } failed with the following message: ${ consentError }`
					});
				}
			}
		}

		// We've made it through all of the checks, so the update was successful
		// Send a promise resolution with the result details, so we have info on which records to clear
		// This will also trigger the success subscription where we can update the remote record.
		return Promise.resolve({
			collectionId: doc.collectionId,
			isError: false
		});
	} catch ( error ) {
		return Promise.reject( error );
	}
}

async function finishRecordSync( result: SyncResult, rxDb: any ) {
	if ( result.isError ) {
		// send error message to state machine
		const existingErroredRecord = await rxDb.collections.localcollections.findOne({
			selector: { collectionId: result.collectionId }
		}).exec();

		if ( existingErroredRecord ) {
			await existingErroredRecord.atomicPatch({
				hasError: true,
				errorAt: getCurrentTimestamp(),
				errorMessage: result.error
			});
		}

		// add the error message to the sync machine:
		syncStatusService.send({
			type: 'ADD_ERROR',
			message: {
				collectionId: result.collectionId,
				error: result.error || ''
			}
		});

		// notify the user of the error:
		toast.error( `Syncing collection ${ result.collectionId } failed with the following message: ${ result.error }` );

		return Promise.resolve();
	}

	// TODO: clear error message
	const updatedDocument = await rxDb.collections.localcollections.findOne({
		selector: {
			collectionId: result.collectionId
		}
	}).exec();

	if ( updatedDocument ) {
		const remoteCollectionResponse = await getCollection( result.collectionId );

		if ( remoteCollectionResponse ) {
			const existingRecord = await rxDb.collections.remotecollections.findOne({
				selector: { collectionId: result.collectionId }
			}).exec();

			if ( existingRecord ) {
				await existingRecord.atomicPatch({
					collectionObject: remoteCollectionResponse,
					isSynced: true,
					updatedAt: getCurrentTimestamp()
				});
			} else {
				await rxDb.collections.remotecollections.insert({
					collectionId: remoteCollectionResponse.id,
					collectionObject: remoteCollectionResponse,
					collectionSiteId: remoteCollectionResponse.collectionSiteId,
					isSynced: true,
					collectionDate: getDateTimestamp( remoteCollectionResponse.collectionDate ),
					updateDate: getCurrentTimestamp(),
					status: remoteCollectionResponse.status
				});
			}
		}

		// clear sync errors (if any):
		syncStatusService.send({ type: 'REMOVE_ERROR', message: result.collectionId });

		// notify the user of the success:
		toast.success( `Collection ${ result.collectionId } synced successfully!` );

		// then remove the updated document from the local collections DB
		await updatedDocument.remove();
	}

	return Promise.resolve();
}

export async function syncRecords() {
	const localDb = await initializeCollections();

	const syncableRecords = await getSyncableRecords( localDb.collections.localcollections );

	const syncablePromises = syncableRecords.map( ( record: any ) => {
		return syncSingleRecord( record );
	});

	const syncResults = await Promise.all( syncablePromises );

	const syncResultsPromises = syncResults.map( ( result: SyncResult ) => {
		return finishRecordSync( result, localDb );
	});

	await Promise.all( syncResultsPromises );
}
