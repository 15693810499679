import type { RxDatabase } from 'rxdb';

import { createRxDatabase, addRxPlugin } from 'rxdb';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration';
import { RxDBEncryptionPlugin } from 'rxdb/plugins/encryption';
import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election';
import { getRxStorageDexie } from 'rxdb/plugins/dexie';

import { collectionSiteSettings } from './schemas/collectionSiteSettings';
import { localcollections } from './schemas/localcollections';
import { applicationSettings } from './schemas/applicationSettings';
import { userData } from './schemas/userData';
import { tempCredentials } from './schemas/credentials';
import { remotecollections } from './schemas/remotecollections';

import { getCurrentTimestamp, getDateTimestamp, defaultDateFormats, getCurrentDateString, serializeDateField } from '@/services/date';
import { getDatabaseName } from '@/services/localDatabase';
import { getCollections, getRecentCollections } from '@/services/webservices';
import { CURRENT_COLLECTION_SITE_KEY, getStorageItem } from '@/stores/localstorage';
import { syncStatusService } from '@/stores/xstate/sync/syncStatusMachine';

addRxPlugin( RxDBMigrationPlugin );
addRxPlugin( RxDBEncryptionPlugin );
addRxPlugin( RxDBLeaderElectionPlugin );

let rxDb: RxDatabase;

async function createDatabase() {
	rxDb = await createRxDatabase({
		name: getDatabaseName( 'occf_collections' ),
		storage: getRxStorageDexie(),
		multiInstance: true,
		password: process.env.NEXT_PUBLIC_RXDB_KEY
	});
}

export async function initialize() {
	if ( !rxDb ) {
		await createDatabase();
	}

	if ( !rxDb.applicationsettings ) {
		const collections = await rxDb.addCollections({
			collectionsitesettings: collectionSiteSettings,
			applicationsettings: applicationSettings,
			userdata: userData,
			localcollections: localcollections,
			remotecollections: remotecollections,
			tmpstorecreds: tempCredentials
		});

		const collectionSiteId = getStorageItem( CURRENT_COLLECTION_SITE_KEY );
		const collectionSiteIdNumber = collectionSiteId ? Number( collectionSiteId ) : '';

		// check for error records
		const errors = await rxDb.collections.localcollections.find({
			selector: {
				hasError: { $eq: true },
				collectionSiteId: collectionSiteIdNumber
			}
		}).exec();

		if ( errors ) {
			errors.forEach( ( document: any ) => {
				const jsonRecord = document.toJSON();

				syncStatusService.send({ type: 'ADD_ERROR', message: {
					collectionId: jsonRecord.collectionId,
					error: jsonRecord.errorMessage
				}});
			})
		}
	}

	return rxDb;
}

export async function fetchCollections() {
	let remotecollections;
	let localcollections;
	let collectionsitesettings;

	const db = await initialize();

	remotecollections = db.remotecollections;
	localcollections = db.localcollections;
	collectionsitesettings = db.collectionsitesettings;

	// set all staged items as synced
	// [TODO]: Confirm whether or not we need this; I don't think we do.
	const stagedCollections = await localcollections?.find({
		selector: { isStaged: { $eq: true } }
	}).exec();

	if ( stagedCollections ) {
		await Promise.all( stagedCollections.map( async ( localDocument: any ) => {
			await localDocument.atomicPatch({
				isSynced: true,
				isStaged: false
			});
		}));
	}

	try {
		const collectionSiteId = getStorageItem( CURRENT_COLLECTION_SITE_KEY );
		const currentSyncTimestamp = getCurrentTimestamp();
		let lastSyncedDatestring = '';

		const collectionSiteDetails = await collectionsitesettings.findOne({
			selector: {
				collectionSiteId: { $eq: collectionSiteId }
			}
		}).exec();

		if ( collectionSiteDetails ) {
			const collectionSiteJson = await collectionSiteDetails.toJSON();

			lastSyncedDatestring = collectionSiteJson.lastUpdateTime;
		}

		let remoteCollections: any;

		if ( !lastSyncedDatestring ) {
			remoteCollections = await getCollections({
				startDate: null,
				endDate: null,
				page: 1,
				pageSize: 999,
				searchTerm: '',
				showCompleted: true,
				isIncludeCollectionConsent: true
			});
		} else {
			remoteCollections = await getRecentCollections({
				lastSyncedDate: lastSyncedDatestring || '',
				startDate: null,
				endDate: null,
				page: 1,
				pageSize: 999,
				searchTerm: '',
				showCompleted: true,
				isIncludeCollectionConsent: true
			});
		}

		if ( collectionSiteDetails ) {
			await collectionSiteDetails.atomicPatch({
				lastUpdateTime: serializeDateField( getCurrentDateString(), defaultDateFormats.input )
			});
		}

		const mappedCollections = remoteCollections.collections.map( ( collection: any ) => {
			let collectionObject = collection;
			
			const matchedConsent = remoteCollections.collectionConsents.find( ( consent: any ) => consent.collectionId === collection.id );

			if ( matchedConsent ) {
				collectionObject = {
					...collectionObject,
					collectionConsent: matchedConsent
				};
			}
	
			return {
				collectionId: collection.id,
				collectionObject,
				collectionSiteId: collection.collectionSiteId,
				isSynced: true,
				collectionDate: getDateTimestamp( collection.collectionDate ),
				updateDate: currentSyncTimestamp,
				status: collection.status
			};
		});
	
		await remotecollections?.bulkUpsert( mappedCollections );
	} catch ( e: any ) {
		throw new Error( e );
	}
}

export async function clearSyncedCollections( remotecollections: any ) {
	const itemsToDelete = await remotecollections.find({}).exec();

	if ( itemsToDelete.length === 0 ) {
		return;
	}

	const idArray = itemsToDelete.map( ( collection: any ) => {
		const parsedDocument = collection.toJSON();

		return parsedDocument.collectionId;
	});

	await remotecollections.bulkRemove( idArray );
}
