import type { CollectionExport } from '@/types/global';

type LocalCollection = {
	collectionId: string;
	collectionObject: string;
	isStaged: boolean;
	isSynced: boolean;
	syncedAt: number;
	createdAt: number;
	createdBy: string;
	updatedAt: number;
	hasError: boolean;
	errorAt: string;
	errorMessage: string;
	collectionSiteId: string;
};

export const localcollections: CollectionExport<LocalCollection> = {
	schema: {
		title: 'localcollections',
		version: 11,
		primaryKey: 'collectionId',
		type: 'object',
		properties: {
			collectionId: {
				type: 'string',
				maxLength: 30
			},
			collectionObject: {
				type: 'string'
			},
			isStaged: {
				type: 'boolean'
			},
			isSynced: {
				type: 'boolean'
			},
			syncedAt: {
				type: 'datetime'
			},
			createdAt: {
				type: 'datetime'
			},
			createdBy: {
				type: 'string'
			},
			updatedAt: {
				type: 'datetime'
			},
			hasError: {
				type: 'boolean'
			},
			errorAt: {
				type: 'datetime'
			},
			errorMessage: {
				type: 'string'
			},
			collectionSiteId: {
				type: 'string'
			}
		},
		encrypted: [ 'collectionObject' ]
	},
	migrationStrategies: {
		4: function( oldDoc ) {
			oldDoc.isSynced = false;
			oldDoc.syncedAt = undefined;

			return oldDoc;
		},
		5: function( oldDoc ) {
			oldDoc.createdAt = new Date();

			return oldDoc;
		},
		6: function( oldDoc ) {
			oldDoc.isStaged = false;

			return oldDoc;
		},
		7: function( oldDoc ) {
			oldDoc.createdBy = '';

			return oldDoc;
		},
		8: function( oldDoc ) {
			return oldDoc;
		},
		9: function( oldDoc ) {
			oldDoc.hasError = false;
			oldDoc.errorAt = '';

			return oldDoc;
		},
		10: function( oldDoc ) {
			oldDoc.errorMessage = '';
			
			return oldDoc;
		},
		11: function( oldDoc ) {
			const parsedCollection = JSON.parse( oldDoc.collection );

			oldDoc.collectionSiteId = parsedCollection.collectionSiteId;

			return oldDoc;
		}
	}
};
