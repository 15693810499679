export const useAzureLogin = process.env.NEXT_PUBLIC_USE_AZURE_AUTH === 'true' || false;

export const showCollectionAddressDetails = process.env.NEXT_PUBLIC_SHOW_EXPANDED_OVERRIDE === 'true' || false;

export const allowCollapsingPoct = process.env.NEXT_PUBLIC_COLLAPSE_POCT === 'true' || false;

export const dbNameExtension = process.env.NEXT_PUBLIC_DB_EXTENSION || undefined; // this is in place in case a full database update needs to be done

export const donorInfoOptional = process.env.NEXT_PUBLIC_DONOR_INFO_OPTIONAL === 'true' || false;

export const alwaysShowMedicationDeclaration = process.env.NEXT_PUBLIC_SHOW_MED_DECLARATION === 'true' || false;

export const isCollectorContactRequired = process.env.NEXT_PUBLIC_REQUIRE_CONTACT_INFO === 'true' || false;

export const pdfTemplateType = process.env.NEXT_PUBLIC_PDF_TEMPLATE || 'efs';

export const pdfPhoneNumbers: Record<string, string[]> = {
	ca: [ '+1 (289) 919-3188', 'Fax: +1 (289) 919-3432' ],
	us: [ '(330) 628-5748', '(800) 665-5569', 'Fax: (330) 628-5803' ]
};

export const inactivityWarningTime = process.env.NEXT_PUBLIC_INACTIVITY_WARNING || 1000 * 60; // 1 minutes
export const inactivityLogoutTime = process.env.NEXT_PUBLIC_INACTIVITY_LOGOUT || 1000 * 60 * 60; // 60 minutes
export const inactivityCheckTime = process.env.NEXT_PUBLIC_INACTIVITY_CHECK || 1000 * 30; // 30 seconds

export const showFindTrp = process.env.NEXT_PUBLIC_SHOW_TRP === 'true' || false;
export const overrideLaboratorySelector = process.env.NEXT_PUBLIC_OVERRIDE_LABORATORY_SELECTOR || undefined;
