import type { ReactNode } from 'react';

import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import { useAzureLogin } from '@/services/config';

type RepinContextProps = {
	shouldRepin: boolean;
	clearRepin: Function;
	setRepin: Function;
};

type RepinProviderProps = {
	children: ReactNode;
};

const RepinContext = createContext<RepinContextProps | undefined>( undefined );

function RepinProvider({ children }: RepinProviderProps ) {
	const { asPath, isReady } = useRouter();

	const shouldCheck = useRef( true );

	const [ shouldRepin, setShouldRepin ] = useState( false );

	// do a first check to see if we're on the signin page. If so, we don't need to check again
	// also check to see if we're using azure login since credentials login doesn't have a PIN
	useEffect( () => {
		if ( asPath.includes( '/auth/signin' ) || !useAzureLogin ) {
			shouldCheck.current = false;

			setShouldRepin( false );
		}
	}, [] );

	useEffect( () => {
		if ( !shouldCheck.current || !isReady ) {
			return;
		}

		if ( asPath.includes( '/auth/signin' ) || asPath.includes( '/auth/error' ) ) {
			shouldCheck.current = false;

			setShouldRepin( false );

			return;
		}

		// if we're on the index route, we're going to get redirected, so let's just ignore it.
		if ( asPath === '/' ) {
			return;
		}

		setShouldRepin( true );
	}, [ asPath, isReady ] );

	const clearRepin = useCallback( () => {
		setShouldRepin( false );

		shouldCheck.current = false;
	}, [ setShouldRepin ] );

	const setRepin = useCallback( () => {
		setShouldRepin( true );

		shouldCheck.current = true;
	}, [ setShouldRepin ] );

	return (
		<RepinContext.Provider value={{
			shouldRepin,
			clearRepin,
			setRepin
		}}>
			{ children }
		</RepinContext.Provider>
	);
}

function useRepin() {
	const context = useContext( RepinContext );

	if ( context === undefined ) {
		throw new Error( 'useRepin must be used within a RepinProvider' );
	}

	return context;
}

export { RepinProvider, useRepin };
