import type { ReactNode } from 'react';
import type { RxCollection, RxDatabase } from 'rxdb';

import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IntlProvider } from 'react-intl';

import { languages } from '@/constants/translations';
import { getTranslations } from '@/services/webservices';

type ProviderProps = {
	children: ReactNode;
	db: RxDatabase;
};

type ReturnValue = {
	switchLocale: Function;
	locales: any[];
};

const TranslationContext = createContext<ReturnValue | undefined>( undefined );

// This function exists to suppress errors while testing because it's very chatty
function errorHandler( error: any ) {
	if ( error.code !== 'MISSING_TRANSLATION' ) {
		console.error( error );
	}
}

async function downloadTranslations( translations: RxCollection ) {
	const translationData = await getTranslations();

	const transformedData = translationData.map( ( language: any ) => {
		let valueObj:any = {};

		language.value.forEach( ({ key, value } : { key: string; value: string; }) => {
			valueObj[ key ] = value;
		});

		return {
			key: language.key,
			value: JSON.stringify( valueObj )
		};
	});

	await translations.bulkUpsert( transformedData );
}

function TranslationProvider({ children, db }: ProviderProps ) {
	const translationsCollection = db.translations;

	const [ currentLocale, setCurrentLocale ] = useState( 'en' );
	const [ currentTranslations, setCurrentTranslations ] = useState({});

	function switchLocale( locale = 'en' ) {
		if ( locale === currentLocale ) {
			return;
		}

		setCurrentLocale( locale );
	}

	// load new translation if locale changes
	const translationsDownloaded = useRef( false );

	useEffect( () => {
		async function loadTranslations( translations: RxCollection, locale: string ) {
			if ( !translations ) {
				return;
			}

			const currentTranslationsRecord = await translations.findOne({
				selector: {
					key: locale
				}
			}).exec();

			if ( !currentTranslationsRecord ) {
				return;
			}

			const record = await currentTranslationsRecord.toJSON();

			setCurrentTranslations( JSON.parse( record.value ) );
		}

		if ( !translationsCollection || !currentLocale ) {
			return;
		}

		if ( !translationsDownloaded.current ) {
			downloadTranslations( translationsCollection );
		}

		loadTranslations( translationsCollection, currentLocale );
	}, [ currentLocale, translationsCollection ] );

	const returnValue: ReturnValue = useMemo( () => ({
		switchLocale,
		locales: languages
	}), [ switchLocale, languages ] );

	return (
		<TranslationContext.Provider value={ returnValue }>
			{ currentTranslations && (
				<IntlProvider
					messages={ currentTranslations }
					locale={ currentLocale }
					defaultLocale="en"
					onError={ errorHandler }
				>
					{ children }
				</IntlProvider>
			)}
		</TranslationContext.Provider>
	);
}

function useTranslation() {
	const context = useContext( TranslationContext );

	if ( context === undefined ) {
		throw new Error( 'useTranslation must be used within a TranslationProvider' );
	}

	return context;
}

export { TranslationProvider, useTranslation };
