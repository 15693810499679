import type { PropsWithChildren } from 'react';

import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

type ConfirmationType = 'delete' | 'saveAndExit' | '';

type ProviderProps = PropsWithChildren;

type StateProps = {
	confirmType: ConfirmationType;
	isOpen: boolean;
	proceed: Function | null;
	cancel: Function | null;
};

const ConfirmContext = createContext<any>( undefined );

function ConfirmProvider({ children } : ProviderProps ) {
	const [ confirm, setConfirm ] = useState<StateProps>({
		confirmType: '',
		isOpen: false,
		proceed: null,
		cancel: null
	});

	const [ needsCleanup, setNeedsCleanup ] = useState( false );

	const isConfirmed = useCallback( ( type: ConfirmationType ) => {
		setNeedsCleanup( true );

		const promise = new Promise( ( resolve, reject ) => {
			setConfirm({
				confirmType: type,
				isOpen: true,
				proceed: resolve,
				cancel: reject
			});
		});

		return promise.then(
			() => {
				setConfirm({ ...confirm, isOpen: false });
				return true;
			},
			() => {
				setConfirm({ ...confirm, isOpen: false });
				return false;
			}
		);
	}, [ confirm, setConfirm, setNeedsCleanup ] );

	useEffect( () => {
		return () => {
			if ( confirm.cancel && needsCleanup ) {
				confirm.cancel();
			}
		};
	}, [ confirm, needsCleanup ] );

	const value = useMemo( () => ({
		...confirm,
		isConfirmed
	}), [ confirm, isConfirmed ] );

	return <ConfirmContext.Provider value={ value }>{ children }</ConfirmContext.Provider>;
}

function useConfirm() {
	const context = useContext( ConfirmContext );

	if ( context === undefined ) {
		throw new Error( 'useConfirm must be used within a ConfirmProvider' );
	}

	return context;
}

export { ConfirmProvider, useConfirm };
