import { assign, createMachine, interpret } from 'xstate';

import { getCurrentDateString } from '@/services/date';
import { checkHeartbeat } from '@/services/connectionStatus';

interface ConnectionStatusContext {
	isOnline: boolean;
	lastChecked: string | undefined;
	isError: boolean;
	errorMessage: string;
}

type ConnectionStatusEvents =
	| { type: 'CHECK' }
	| { type: 'CONNECTION_ACTIVE' }
	| { type: 'CONNECTION_INACTIVE' };

type ConnectionStatusTypestate =
	| { value: 'checking'; context: ConnectionStatusContext; }
	| { value: 'idle'; context: ConnectionStatusContext; };

function setConnectionStatus( context: ConnectionStatusContext, status: boolean ) {
	return {
		...context,
		isOnline: status,
		lastChecked: getCurrentDateString()
	};
}

function setErrorMessage( context: ConnectionStatusContext, isError: boolean, errorMessage: string ) {
	return {
		...context,
		isError,
		errorMessage,
		lastChecked: getCurrentDateString()
	};
}

export const offlineMachine = createMachine<ConnectionStatusContext, ConnectionStatusEvents, ConnectionStatusTypestate>({
	id: 'ccfOffline',
	initial: 'offline',
	context: {
		isOnline: false,
		lastChecked: undefined,
		isError: false,
		errorMessage: ''
	},
	on: {
		CHECK: {
			target: 'checking'
		},
		CONNECTION_ACTIVE: {
			target: 'online'
		},
		CONNECTION_INACTIVE: {
			target: 'offline'
		}
	},
	states: {
		offline: {
			id: 'offline',
			entry: [ 'setStatusOffline' ]
		},
		online: {
			id: 'online',
			entry: [ 'setStatusOnline' ]
		},
		checking: {
			id: 'checking',
			invoke: {
				id: 'checkService',
				src: 'checkOnlineStatus',
				onDone: {
					target: 'online',
				},
				onError: {
					target: 'offline',
				}
			}
		}
	}
}, {
	actions: {
		setError: assign( ( context: any, event: any ) => ({
			...setErrorMessage( context, true, event.message )
		})),
		clearError: assign( ( context: any ) => ({
			...setErrorMessage( context, false, '' )
		})),
		setStatusOnline: assign( ( context: any ) => ({
			...setConnectionStatus( context, true )
		})),
		setStatusOffline: assign( ( context: any ) => ({
			...setConnectionStatus( context, false )
		}))
	},
	services: {
		checkOnlineStatus: checkHeartbeat
	}
});

export const service = interpret( offlineMachine ).start();
