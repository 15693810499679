const stateKey: string = process.env.NEXT_PUBLIC_PERSISTED_STATE_KEY || '';
export const CURRENT_COLLECTION_SITE_KEY = 'currentcollectionsite';

function storageAvailable( type: string ) {
	let storage: any;

	try {
		storage = (<any>window)[ type ];

		const x = '__storage_test__';

		storage.setItem( x, x );
		storage.removeItem( x );

		return true;
	} catch ( e ) {
		return e instanceof DOMException && (
			e.name === 'QuotaExceededError' ||
			e.name === 'NS_ERROR_DOM_QUOTA_REACHED'
		) && ( storage && storage.length !== 0 );
	}
}

export function getStorageItem( key: string ) {
	if ( storageAvailable( 'localStorage' ) ) {
		return key ? localStorage.getItem( key ) : '';
	} else {
		return '';
	}
}

export function setStorageItem( key: string, val: string ) {
	// TODO: Error handling.
	if ( key && storageAvailable( 'localStorage' ) ) {
		localStorage.setItem( key, val );
	}
}

export function removeStorageItem( key: string ) {
	if ( key ) {
		localStorage.removeItem( key );
	}
}

export function clearLocalState() {
	removeStorageItem( stateKey );
}

export function setLocalState( val: string ) {
	setStorageItem( stateKey, val );
}

export function getLocalState() {
	return getStorageItem( stateKey );
}

export function setLocalTemplate( val: any ) {
	setStorageItem( 'templateCollection', val );
}

export function clearLocalTemplate() {
	removeStorageItem( 'templateCollection' );
}

export function getLocalTemplate() {
	return getStorageItem( 'templateCollection' );
}
