import type { ReactNode } from 'react';

type HeaderElement = {
	children: ReactNode;
	className: string;
};

type HeaderProps = {
	children: ReactNode;
	element: string;
	variant?: string;
	className?: string;
};

const ElementMap: { [ key: string ]: Function } = {
	'h1': H1,
	'h2': H2,
	'h3': H3,
	'h4': H4,
	'h5': H5,
	'h6': H5
};

const ClassMap: { [ key: string ]: string } = {
	'h1': 'text-xl md:text-4xl font-bold mb-3',
	'h2': 'text-xl mb-3 md:text-3xl md:mb-5 font-bold',
	'h3': 'text-lg mb-3 md:text-2xl md:mb-4 font-medium',
	'h4': 'text-md md:text-xl font-medium',
	'h5': 'text-md md:text-lg font-medium',
	'h6': 'text-base font-medium'
};

function H1({ children, className } : HeaderElement ) {
	return <h1 className={ className }>{ children }</h1>;
}

function H2({ children, className } : HeaderElement ) {
	return <h2 className={ className }>{ children }</h2>;
}

function H3({ children, className } : HeaderElement ) {
	return <h3 className={ className }>{ children }</h3>;
}

function H4({ children, className } : HeaderElement ) {
	return <h4 className={ className }>{ children }</h4>;
}

function H5({ children, className } : HeaderElement ) {
	return <h5 className={ className }>{ children }</h5>;
}

function H6({ children, className } : HeaderElement ) {
	return <h6 className={ className }>{ children }</h6>;
}

export default function Header({
	children,
	element,
	variant,
	className = ''
} : HeaderProps ) {
	const Component = ElementMap[ element ];
	const classValue = ClassMap[ variant || element ];

	return (
		<Component className={ `${ classValue } ${ className }` }>{ children }</Component>
	);
}

