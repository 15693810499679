import type { RxCollection } from 'rxdb';

import { dbNameExtension } from '@/services/config';
import { getCollectionSiteDetails, getApplicationSettings } from '@/services/webservices';
import { CURRENT_COLLECTION_SITE_KEY, getStorageItem, setStorageItem } from '@/stores/localstorage';

export function getDatabaseName( dbName: string ) {
	return [ dbName, dbNameExtension || undefined ].filter( a => a ).join( '_' );
}

export async function loadCollectionSiteDetails( collectionSiteCollection: RxCollection ) {
	try {
		const settingsData = await getCollectionSiteDetails();
	
		const {
			clientLogos,
			collectionSite,
			requestingAgencies,
			testSubprocesses,
			testSubstanceResults,
			testTemplates
		} = settingsData;

		setStorageItem( CURRENT_COLLECTION_SITE_KEY, collectionSite.id );

		const existingRecord = await collectionSiteCollection.findOne({
			selector: { collectionSiteId: { $eq: '' + collectionSite.id } }
		}).exec();

		if ( existingRecord ) {
			await existingRecord.atomicPatch({
				clientLogos: JSON.stringify( clientLogos || {} ),
				collectionSiteId: '' + collectionSite.id,
				collectionSite: JSON.stringify( collectionSite || {} ),
				requestingAgencies: JSON.stringify( requestingAgencies || [] ),
				testSubprocesses: JSON.stringify( testSubprocesses || [] ),
				testSubstanceResults: JSON.stringify( testSubstanceResults || [] ),
				testTemplates: JSON.stringify( testTemplates || [] )
			});
		} else {
			await collectionSiteCollection.insert({
				clientLogos: JSON.stringify( clientLogos || {} ),
				collectionSiteId: '' + collectionSite.id,
				collectionSite: JSON.stringify( collectionSite || {} ),
				requestingAgencies: JSON.stringify( requestingAgencies || [] ),
				testSubprocesses: JSON.stringify( testSubprocesses || [] ),
				testSubstanceResults: JSON.stringify( testSubstanceResults || [] ),
				testTemplates: JSON.stringify( testTemplates || [] ),
				lastUpdateTime: ''
			});
		}
	} catch ( e: any ) {
		console.error( 'There was an error loading collection site details: ', e.toString() );
	}
}

export async function loadApplicationSettings( applicationsettings: RxCollection ) {
	const settingsData = await getApplicationSettings();

	const {
		ccfApplicationSettings,
		testReasons,
		hairLocationLookupValues,
		anonymousTestLookupValues,
		languageLookupValues,
		yesNoLookupValues,
		testSubprocessResultLookupValues,
		donorSexLookupValues,
		hairTypeLookupValues,
		sampleTypes,
		splitTypeValues,
		overallResultTypes,
		typesOfTest,
		testingAuthorities
	} = settingsData;

	await applicationsettings.upsert({
		settingsId: '1',
		ccfApplicationSettings: JSON.stringify( ccfApplicationSettings ),
		testReasons: JSON.stringify( testReasons ),
		hairLocationLookupValues: JSON.stringify( hairLocationLookupValues ),
		anonymousTestLookupValues: JSON.stringify( anonymousTestLookupValues ),
		languageLookupValues: JSON.stringify( languageLookupValues ),
		yesNoLookupValues: JSON.stringify( yesNoLookupValues ),
		testSubprocessResultLookupValues: JSON.stringify( testSubprocessResultLookupValues ),
		donorSexLookupValues: JSON.stringify( donorSexLookupValues ),
		hairTypeLookupValues: JSON.stringify( hairTypeLookupValues ),
		sampleTypes: JSON.stringify( sampleTypes ),
		splitTypeValues: JSON.stringify( splitTypeValues ),
		overallResultTypes: JSON.stringify( overallResultTypes ),
		typesOfTest: JSON.stringify( typesOfTest ),
		testingAuthorities: JSON.stringify( testingAuthorities )
	});
}
